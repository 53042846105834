/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
// import ReactGA from 'react-ga'
// import TagManager from 'react-gtm-module'
import isEqual from 'lodash/isEqual'
import {
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'
import pako from 'pako'
// import isEmpty from 'lodash/isEmpty'

import ScreenHeader from './components/ScreenHeader'
import Common from './containers/Common/Common-redux'
import SideBar from './components/SideBar'
// import CircleLoader from './UI/CircleLoader'
import CustomModal from './UI/CustomModal'
import Icon from './UI/Icon'
import PageLoader from './UI/PageLoader'
import SnackBar from './UI/Snackbar'
import UnAuthenticatedRoutes, { UnauthRoutes } from './UnAuthenticatedRoutes'
import Authenticator from './containers/Authenticator'
import AuthenticatedRoutes, { authRoutes } from './AuthenticatedRoutes'
import CommonRoutes, { commonRoutes } from './CommonRoutes'
import HomeRoutes from './HomeRoutes'
// import UpgradeModal from './components/UpgradeModal'
import LoginModal from './UI/LoginModal'
import WelcomeRewardModal from './UI/WelcomeRewardModal'
import ProductTour from './components/ProductTour/ProductTour'
// import GAScript from './GAScript'

// import Auth from './containers/Auth/Auth-redux'
import { withTheme } from './Theme/ThemeProvider'
import { pathname_mapping } from './utils/navigationConstant'
import { BAR_TYPE_MAP } from './components/SideBar/SideBar'
import {
  orginMap,
  DISPLAY_DEVICE,
} from './utils/consts'
import { PRO_FEATURE_MAP } from './utils/userAccessChecks'
import {
  parseQuery,
  getLocalStorage, getCurrentBroker,
} from './utils/common'
import {
  ICONS, SPACING, theme, COLORS, ASSETS,
} from './Theme'
import home_pathname_mapping from './homepage/homeutils/navigationConstant'

import {
  logout,
  // setToken,
  fetchVersion,
  validateSession,
  loginUser,
  fetchVersionSuccess,
  setToken,
  updatePartnerRef,
  toggleLoginModal,
  toggleDeeplinkModal,
} from './containers/Auth/actions'
import { getNotificationCount } from './containers/Notifications/actions'
import { PREFERENCES } from './containers/Profile/profileUtils'
import {
  updateWebSiteConfig, toggleAuthGreeting, toggleTour,
  updateTourIndex, togglePtSideBar, getSideBarValue,
  pollUser,
} from './containers/Common/actions'
import { btWasmUrl } from './utils/apiEndpoints'
import { changeWasmStatus } from './containers/Backtests/actions'
import { isCtbFlow, isLoginFLow } from './containers/Create/Create-utils'
import DeeplinkModal from './UI/DeeplinkModal'
import ProductTourSideBar from './components/ProductTour/ProductTourSideBar'
import { PRODUCT_TOUR_SECTIONS } from './components/ProductTour/ProductTour-utils'
import DiscloureModal from './UI/DiscloureModal'
import CustomText from './UI/CustomText'
// import Plans from './homepage/components/Plans'
// import Home from './homepage/containers/Home/Home'

const ROUTES = {
  AUTH: 'auth',
  APP: 'app',
  MOBILE_AUTH: 'mobileAuth',
  ONBOARDING: 'onboarding',
  TERMS: 'terms',
  LOADING: 'loading',
  LINKING_BROKER: 'linking',
}

const sideBarWidth = 294
const condensedSideBarWidth = 90

export const derivedBroker = getCurrentBroker()

const wasmExec = 'https://streak-public-assets.s3.ap-south-1.amazonaws.com/bt_dist/wasm_exec.js'

class App extends Component {
  constructor(props) {
    super(props)
    const version = orginMap()[window.location.host] || 3
    this.unAuthorized = false
    this.initialMount = false
    this.finalWasm = null
    this.queryParams = {}
    this.scrollRef = React.createRef()
    this.state = {
      version,
      loggedIn: true,
      password: '',
      hideSideBar: true,
      activeSideBarObj: {},
      showUpgradeModal: false,
      authScreens: authRoutes.some(route => window.location.pathname.includes(route.path)),
      commonScreens: commonRoutes.some(route => window.location.pathname.includes(route.path)),
      unAuthScreens: UnauthRoutes.some(route => window.location.pathname.includes(route.path)),
      referralModal: false,
      marketingHomePageScrolled: false,
      isDeployedPage: false,
      showLimitPopup: false,
      showLoading: true,
    }
    this.sideBarRef = React.createRef()

    this.upgradeModalProps = {}
  }

  componentDidMount() {
    const { location } = this.props
    const { pathname, search } = location
    const fullPath = `${pathname + search}`
    const deployedPage = fullPath.includes(pathname_mapping.live)
      || fullPath.includes(pathname_mapping.paper)
    this.setState({
      isDeployedPage: deployedPage,
    })
    this.getSideBarState(deployedPage)
    this.updatePreferences()
    this.initializeGA()
    const marketingHomepage = window.location.pathname === pathname_mapping.SystematicTrading
      || window.location.pathname.toLocaleLowerCase() === pathname_mapping.Ctb.toLocaleLowerCase()
    const isCtb = isCtbFlow('', true)
    const isLogin = isLoginFLow()
    // const { ctb: isCtbBt } = parseQuery(search, true)

    // const isMobileBrowser = navigator.userAgent
    // && (navigator.userAgent.includes('Android')
    // || navigator.userAgent.includes('ios'))
    const isIOS = navigator.userAgent && navigator.userAgent.includes('iPhone')
    if (isIOS && (isCtb || isLogin)) {
      this.openAppStore()
    }

    if (!marketingHomepage && isCtb) {
      this.initializeWASM()
    }

    const mainEle = document.getElementById('main')
    if (mainEle) {
      mainEle.addEventListener('scroll', this.scrollListener)
      this.scrollListenerAttached = true
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      greetingModal,
      isLoggedIn,
      user_details,
      toggleTheme,
      themeMode,
      history,
      isWasmLoaded,
      loadWasm,
      isFetchingUser,
    } = this.props
    const { pathname, search } = location
    const { user_uuid, subscription_valid = false } = user_details
    if (!isEqual(user_details, prevProps.user_details) && !isFetchingUser) {
      this.pollHandler(user_details.user_uuid)
    }
    if (!isEqual(location, prevProps.location)) {
      if (user_details) {
        this.pollHandler(user_uuid)
      }
      const fullPath = `${pathname + search}`
      const deployedPage = fullPath.includes(pathname_mapping.live)
        || fullPath.includes(pathname_mapping.paper)
      this.setState({
        isDeployedPage: deployedPage,
      })
      const isCtb = isCtbFlow()
      this.updateGA()
      if (this.scrollRef.current && (location.pathname + location.hash) !== (`${pathname_mapping.Home}#pricing`)
        && (location.pathname + location.hash) !== (`${pathname_mapping.Auth}#pricing`)) {
        this.windowScrollTimer = setTimeout(() => {
          this.scrollRef.current.scrollTo(0, 0)
        }, 150)
      }
      if (location.pathname !== pathname_mapping.SystematicTrading) {
        this.setState({ marketingHomePageScrolled: false })
      }
      if (!isWasmLoaded && isCtb) {
        this.initializeWASM()
      }
      this.getSideBarState(deployedPage)
      const authScreens = authRoutes.some(route => location.pathname.includes(route.path))
      const commonScreens = commonRoutes.some(route => location.pathname.includes(route.path))
      const unAuthScreens = UnauthRoutes.some(route => location.pathname.includes(route.path))
      this.setState({
        commonScreens, authScreens, unAuthScreens,
      })
      if (!authScreens) {
        document.title = 'Streak | Create, backtest and deploy'
      }
      const defaultMode = getLocalStorage(PREFERENCES.THEME)
      const authenticated = isLoggedIn && (user_details.status && user_details.status === 'success')
      if (defaultMode && defaultMode !== themeMode && (
        authScreens || (authenticated && commonScreens))
      ) {
        toggleTheme(defaultMode)
      }

      if (authenticated
        && location.pathname === pathname_mapping.Auth) {
        history.replace(pathname_mapping.Dashboard)
      }
    }

    if (!isEqual(loadWasm, prevProps.loadWasm) && loadWasm) {
      this.initializeWASM()
    }

    if (!isEqual(greetingModal, prevProps.greetingModal)) {
      this.upgradeModalProps = PRO_FEATURE_MAP[greetingModal] || {}
      this.setState({ showUpgradeModal: !!greetingModal })
    }

    if (!isEqual(prevProps.user_details.subscription_valid, subscription_valid)
      && subscription_valid) {
      this.onDismissLimitPopup()
    }

    // if(!isEqual(user_details, prevProps.user_details) && !isFetchingUser) {
    //   if(user_details.subscription_plan === 'free' && user_details.broker === 'zerodha') {
    //     window.open(OpenStreakKiteLoginUrl, '_self')
    //   } else {
    //     this.setState({ showLoading: false })
    //   }
    // }
  }

  componentWillUnmount = () => {
    if (this.requestDemoTimer) {
      clearTimeout(this.requestDemoTimer)
    }
    if (this.windowScrollTimer) clearTimeout(this.windowScrollTimer)
    // window.removeEventListener('focus', this.windowFocusHandler)
    // window.removeEventListener('blur', this.toggleWindowActive)
  }

  scrollListener = () => {
    const homeContainerEle = document.getElementById('systematicTradingContainer')
    if (homeContainerEle) {
      const homeContainerBounding = homeContainerEle.getBoundingClientRect()

      if (homeContainerBounding.top < -240) {
        this.setState({ marketingHomePageScrolled: true })
      }
      if (homeContainerBounding.top > -230) {
        this.setState({ marketingHomePageScrolled: false })
      }
    }
  }

  updatePreferences = () => {
    const { updatePreferences } = this.props
    const preferences = getLocalStorage(PREFERENCES.KEY)
    if (preferences) {
      updatePreferences(JSON.parse(preferences))
    }
  }

  initializeGA = () => {
    // ReactGA.initialize('UA-110056397-4', {
    //   linker: {
    //     domains: ['streak.tech'], // 'localhost:3000'
    //   },
    //   testMode: process.env.NODE_ENV === 'development',
    // })
    // TagManager.initialize({ gtmId: 'GTM-NDWNSG2' })
    // GAScript()
  }

  updateGA = () => {
    try {
      // const page = window.location.search && window.location.search.includes('tab=')
      //   ? window.location.pathname + window.location.search : window.location.pathname
      // ReactGA.pageview(page)
    } catch (e) {
      console.log(e.message, 'ga error')
    }
  }

  initializeWASM = () => {
    this.loadScript(wasmExec, this.onLoadWasmExec)
  }

  loadCallback = (callbackFunc) => {
    if (callbackFunc) callbackFunc()
  }

  loadScript = (url = '', callbackFunc) => {
    if (url) {
      const scriptEle = document.createElement('script')
      if (callbackFunc) scriptEle.onload = () => this.loadCallback(callbackFunc)
      document.body.appendChild(scriptEle).src = url
    }
  }

  onLoadWasmExec = async () => {
    const { updateWasmStatus } = this.props
    const go = new window.Go()
    let buffer = pako.ungzip(await (await fetch(btWasmUrl)).arrayBuffer())

    // A fetched response might be decompressed twice on Firefox.
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=610679
    if (buffer[0] === 0x1f && buffer[1] === 0x8b) {
      buffer = pako.ungzip(buffer)
    }

    WebAssembly.instantiate(buffer, go.importObject).then((result) => {
      go.run(result.instance)
      updateWasmStatus(true)
    })
  }

  fetchWASM = (url) => {
    return new Promise((resolve, reject) => {
      try {
        window.caches.open(url).then((cache) => {
          return cache.match(url).then((response) => {
            if (response) {
              console.log(' Found response in cache:', response)
              const reader = response.body.getReader()
              return reader.read().then(({ done, value }) => {
                if (!done) {
                  const uintArray = new Uint16Array(value)
                  const converted = []
                  uintArray.forEach((byte) => { converted.push(String.fromCharCode(byte)) })
                  this.finalWasm = converted.join('')
                  resolve()
                }
              }).catch(err => reject(err))
            }
            return fetch(url).then((resp) => {
              console.log('Response from network is', url, resp)
              if (resp.status < 400 && resp.ok) {
                const reader = resp.body.getReader()
                return reader.read().then(({ done, value }) => {
                  if (!done) {
                    // const svgImg = String.fromCharCode(...new Uint8Array(value))

                    const uintArray = new Uint16Array(value)
                    // const converted = []
                    // debugger
                    // uintArray
                    //   .forEach((byte) => { converted.push(String.fromCharCode(byte)) })

                    // cache.add(url)
                    this.finalWasm = new TextDecoder().decode(uintArray) // converted.join('')
                    resolve()
                  }
                }).catch(err => reject(err))
              }
              return ''
            })
          })
        })
      } catch (error) {
        console.log(error)
      }
    })
  }
  // updateSiteConfig = () => {
  //   const config = getLocalStorage(PREFERENCES.SITE_CONFIG)
  //   if (config) {
  //     this.props.updateSiteConfig(null, JSON.parse(config))
  //   }
  // }

  // resetCtbCookie = () => {
  //   const { ctb, callType } = getCookies()
  //   if (ctb || callType) {
  //     deleteCookies(['ctb', 'callType'])
  //   }
  // }

  check = (e) => {
    e.preventDefault()
    const { password } = this.state
    if (password === 'newui321321') {
      this.setState({ loggedIn: true })
    } else {
      this.setState({ password: '' })
    }
  }

  fallback = () => {
    const { styles } = this.props
    return (
      <div className={styles.fallback}>
        <PageLoader fullScreen />
      </div>
    )
  }

  getSideBarState = (deployedSideBar) => {
    const { location, websiteConfig = {}, isMobile } = this.props
    if (isMobile) return
    const { pathname, search } = location
    const { sideBarState } = websiteConfig
    let activeSideBarObj = BAR_TYPE_MAP[deployedSideBar ? pathname_mapping.Deployed : pathname]
      || {}
    let hideSideBar = Boolean(!BAR_TYPE_MAP[pathname]
      || (BAR_TYPE_MAP[pathname] && !BAR_TYPE_MAP[pathname].show)
      || (sideBarState && sideBarState[pathname] === '0'))
    if (search && BAR_TYPE_MAP[pathname] && BAR_TYPE_MAP[pathname].subComponent) {
      const queryMap = Object.values(parseQuery(search))
      BAR_TYPE_MAP[pathname].subComponent.some((subComp) => {
        if (queryMap.includes(subComp.tabKey)) {
          activeSideBarObj = subComp
          hideSideBar = (sideBarState && sideBarState[`${pathname}?${subComp.tabKey}`] === '0')
            || Boolean(!subComp.Component)
          return true
        }
        return false
      })
    }
    if (this.sideBarRef.current) this.sideBarRef.current.style.position = hideSideBar ? 'absolute' : 'sticky'
    this.setState({ hideSideBar, activeSideBarObj })
  }

  toggleSideBar = (e, hide) => {
    const {
      websiteConfig = {}, updateSiteConfig, location, isMobile, updateGA,
      setSideBarValue,
    } = this.props
    if (isMobile) return
    const { activeSideBarObj, hideSideBar } = this.state
    setSideBarValue(hideSideBar)
    if (this.sideBarRef.current) {
      if (hideSideBar) {
        this.sideBarRef.current.style.position = 'absolute'
        if (this.sideBarStyleTimer) clearTimeout(this.sideBarStyleTimer)
        this.sideBarStyleTimer = setTimeout(() => {
          this.sideBarRef.current.style.position = 'sticky'
        }, 450)
      } else {
        this.sideBarRef.current.style.position = 'absolute'
      }
    }
    let modSideBarState = websiteConfig.sideBarState || {}
    const pathname = activeSideBarObj.tabKey ? `${location.pathname}?${activeSideBarObj.tabKey}`
      : location.pathname
    modSideBarState = {
      ...modSideBarState,
      [pathname]: hideSideBar ? '1' : '0',
    }
    this.setState({ hideSideBar: !hideSideBar })
    updateSiteConfig('sideBarState', modSideBarState)
    if (hide !== undefined) {
      const gaAction = hide ? 'Close button' : 'Open gear icon'
      let gaLabel = ''
      let gaCategory = ''
      if (location.pathname === pathname_mapping.Backtests
        || location.pathname === pathname_mapping.MarketplaceBacktest
      ) {
        gaLabel = 'Backtest parameters'
        gaCategory = 'Edit parameters'
      } else if (location.pathname === pathname_mapping.SharedBacktest) {
        gaLabel = 'Shared backtest parameters'
        gaCategory = 'Edit parameters'
      } else if (location.pathname === pathname_mapping.ScanDetails) {
        gaLabel = 'Scanner parameters'
        gaCategory = 'Scanner parameters'
      }
      updateGA(gaAction, gaLabel, 1, gaCategory)
    }
  }

  pollHandler = (user_uuid) => {
    const { pollUserDetails, location } = this.props
    const params = {
      user_uuid,
      view: location.pathname,
    }
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    pollUserDetails(params, headers)
  }

  renderScreens = ({
    authenticated, domainChange, activeRoute, toggleAuthModal,
  }) => {
    if (activeRoute === ROUTES.LOADING) {
      return (
        <PageLoader fullScreen />
      )
    }
    if (activeRoute === ROUTES.MOBILE_AUTH) {
      return (
        <Switch>
          {UnAuthenticatedRoutes({ domainChange })}
          <Redirect to={pathname_mapping.Auth} />
        </Switch>
      )
    }
    if (activeRoute === ROUTES.AUTH) {
      return (
        // <Auth
        //   domainChange={domainChange}
        // />
        <Switch>
          {HomeRoutes({ domainChange, toggleLoginModal: toggleAuthModal })}
          {CommonRoutes({ domainChange, toggleLoginModal: toggleAuthModal })}
          {UnAuthenticatedRoutes({ domainChange })}
          <Redirect to={home_pathname_mapping.Login} />
        </Switch>
      )
    }
    if (activeRoute === ROUTES.APP && authenticated) {
      return (
        <Switch>
          {HomeRoutes({ domainChange, toggleLoginModal: toggleAuthModal })}
          {AuthenticatedRoutes()}
          {UnAuthenticatedRoutes({ domainChange })}
          {CommonRoutes({ domainChange })}
          <Redirect to={pathname_mapping.Dashboard} />
        </Switch>
      )
    }
    return <PageLoader fullScreen />
    //  (
    //   <UnAuthenticatedRoutes
    //     domainChange={domainChange}
    //   />
    // )
  }

  routeToDashboard = () => {
    this.props.history.push({
      pathname: pathname_mapping.Dashboard,
    })
  }

  routeToDiscover = () => {
    this.props.history.push({
      pathname: pathname_mapping.Discover,
      search: '?tab=top_performer',
    })
  }

  routeToScanners = () => {
    this.props.history.push({
      pathname: pathname_mapping.popular,
      search: '?tab=discover_popular',
    })
  }

  routeToPricing = () => {
    const { history, isLoggedIn, location } = this.props
    if (isLoggedIn) {
      history.push(pathname_mapping.pricing)
    } else if (location.pathname === pathname_mapping.Auth) {
      window.open('#pricing', '_self')
    } else {
      history.push(`${pathname_mapping.Auth}#pricing`)
    }
  }

  routeToHome = () => {
    this.props.history.push({
      pathname: pathname_mapping.Home,
    })
  }

  routeToLogin = (routeFrom, broker, preventGA) => {
    const { updateGA } = this.props
    if (!preventGA) updateGA('Log in', routeFrom, 1, 'Home', 'Search')
    const pathname = broker ? `${pathname_mapping.Auth}/${broker}` : pathname_mapping.Auth
    this.props.history.push({
      pathname,
    })
  }

  onDismissLoginModal = () => {
    const { toggleAuthModal } = this.props
    toggleAuthModal(false)
  }

  openInApp = () => {
    // const { updateGA } = this.props
    // updateGA('Open in app', 'Home', 1, 'Home', 'Search')
    const url = window.location.href
    // const url = 'https://streak.tech/login/angel/create/d4d2fe20-4d50-4cc8-9e9f-eb76708f58cd?stock=NSE_ASHOKLEY'
    // window.open(`intent://app/#Intent;scheme=streak;package=com.streak;S.url=${url};end`, '_blank')
    // window.open(`streak://auth_redirect?url=${url}`, '_blank')
    window.open(`https://ref.streak.tech/?link=${url}&apn=com.streak&amv=180&ibi=tech.streak.trade&isi=1423080803&ius=streak&_imcp=1`, '_blank')
  }

  openAppStore = () => {
    // redirect to  https://apps.apple.com/in/app/streak/id1423080803
    window.open('https://apps.apple.com/in/app/streak/id1423080803', '_self')
  }

  routeToPayment = (url) => {
    const { history } = this.props
    history.push(url)
    this.setState({ showUpgradeModal: false })
  }

  toggleUpgradeModal = () => {
    const { toggleGreetingModal } = this.props
    toggleGreetingModal()
    const { showUpgradeModal } = this.state
    this.setState({ showUpgradeModal: !showUpgradeModal })
  }

  toggleDeepLinkModal = () => {
    const { toggleDeepLinkModal } = this.props
    toggleDeepLinkModal(false)
  }

  onDismissLimitPopup = () => {
    const { showLimitPopup } = this.state
    this.setState({ showLimitPopup: !showLimitPopup })
  }

  renderLimitPopup = () => {
    const { styles } = this.props
    const { showUpgradeModal } = this.state

    return (
      <>
        <div className={styles.limitHeader}>
          <div />
          <CustomText size="medium" weight="medium" color={COLORS.RED}>Important Notice!</CustomText>
          <button
            type="button"
            onClick={() => (showUpgradeModal
              ? this.toggleUpgradeModal() : this.onDismissLimitPopup())}
            style={{
              position: 'relative',
              top: '-20px',
              left: '20px',
            }}
          >
            <Icon
              name={ICONS.CLOSE}
              size={12}
            />
          </button>
        </div>

        <CustomText style={{ marginBlock: SPACING.SPACE_20 }} center>
          Any users without a valid paid subscription plan will not be
          able to use the platform (pro.streak.tech).
        </CustomText>
        <CustomText center>
          All the existing clients with valid subscriptions, can use this platform (pro.streak.tech)
          till your plan is valid. You will not be able to subscribe to any new plans.
        </CustomText>
      </>
    )
  }

  render() {
    const {
      loginError, user_details, isLoggedIn, styles, displayDevice, isMobile, location,
      history, loginResp, isDark, toggleTheme, isFetchingUser, setPartnerRef,
      updatePreferences, preferences, showLoginModal, startTour, toggleAuthModal,
      postLoginParams, updateGA, showDeeplinkModal, showFeatures, ptSideBar,
      tourType, activeTourIndex, toggleTourSideBar, sharedLogin, isLoginOnOrder,
    } = this.props
    const {
      hideSideBar, activeSideBarObj, version, showUpgradeModal, authScreens, commonScreens,
      unAuthScreens, marketingHomePageScrolled, isDeployedPage, showLimitPopup,
      // showLoading,
    } = this.state
    const path = location.pathname.includes(pathname_mapping.Dashboard)
    const isCondensed = isDeployedPage && !isMobile
      && !hideSideBar && displayDevice >= DISPLAY_DEVICE.DESKTOP.value
    const ptClassName = tourType === PRODUCT_TOUR_SECTIONS.DEPLOY && activeTourIndex === 5 ? 'tour-dot-deploy' : ''
    const authenticated = isLoggedIn && (user_details.status && user_details.status === 'success')
      && (!loginError || (loginResp.csrf && loginError)) // last condition is for switching broker
    // if (!isLoggedIn || !loggedIn || loginError
    //   || !user_details.status || ) {
    //   return (
    //     <div>
    //       Login in to continue
    //       <input style={{ border: '1px solid' }}
    // value={password} onChange={e => this.setState({ password: e.target.value })} />
    //       <button type="button" onClick={this.check}>login</button>
    //     </div>
    //   )
    // }
    // console.log(isLoggedIn, user_details.status, loginError, 'lol status')
    // const { hostname } = window.location
    // const isZerodha = hostname === 'streak.zerodha.com'
    return (
      <main className={`${styles.main}`} data-tour={startTour ? 'tour' : ''} id="main" ref={this.scrollRef}>
        <Authenticator
          derivedBroker={derivedBroker}
          ROUTES={ROUTES}
          location={location}
          version={version}
          history={history}
          toggleTheme={toggleTheme}
          setPartnerRef={setPartnerRef}
          authenticated={authenticated}
          updateGA={updateGA}
        >
          {({
            activeRoute,
            domainChange,
            switchDomain,
            isWebView,
            showPageLoader,
            openWelcomeReward,
            toggleModal,
            onDismissWelcomeReward,
            showDisclosureModal,
            onDismissDisclosureModal,
            inComingBroker,
          }) => {
            const loggedIn = authenticated && activeRoute === ROUTES.APP
            const withBg = (location.pathname === pathname_mapping.Auth
              || location.pathname === pathname_mapping.SignUp)
              && activeRoute === ROUTES.AUTH
            const isHomePage = location.pathname.includes(pathname_mapping.Home)
            const currentPath = location.pathname.toLowerCase()
            const isNewHomePage = currentPath === pathname_mapping.SystematicTrading.toLowerCase()
            const isDirectOrder = currentPath === pathname_mapping.OpenScanOrder.toLowerCase()
              || currentPath.toLowerCase() === pathname_mapping.Ctb.toLowerCase()
            const isCharts = location.pathname === pathname_mapping.Chart
            let stylesProps = { className: styles.authScreens, id: 'authScreeens' }
            if (isHomePage || isNewHomePage || isDirectOrder) {
              stylesProps = { id: 'homeScreens', className: styles.authScreens }
            } else if (isCharts) {
              stylesProps = { className: styles.authScreens, id: 'chartScreen' }
            }
            // console.log(activeRoute, authenticated, user_details, 'lol activeRoute')
            // console.log(showPageLoader, 'lol isHomePage')
            return (
              <>
                {showPageLoader && <PageLoader isLoading={showPageLoader} fullScreen />}

                {/* <CircleLoader /> */}
                {(authenticated || isLoginOnOrder) && (
                  <Common
                    activeRoute={activeRoute}
                    updatePreferences={updatePreferences}
                    preferences={preferences}
                    location={location}
                    isWebView={isWebView}
                    isLoginOnOrder={isLoginOnOrder}
                  />
                )}
                {activeRoute !== ROUTES.MOBILE_AUTH && !isWebView
                  && !isCharts && (
                    <ScreenHeader
                      loggedIn={loggedIn}
                      routeToDashboard={this.routeToDashboard}
                      routeToLogin={this.routeToLogin}
                      routeToDiscover={this.routeToDiscover}
                      routeToScanners={this.routeToScanners}
                      routeToHome={this.routeToHome}
                      switchDomain={switchDomain}
                      isHomePage={isHomePage}
                      isNewHomePage={isNewHomePage}
                      domainChange={domainChange}
                      pathname={location.pathname}
                      search={location.search}
                      updateGA={updateGA}
                      authScreens={authScreens}
                      commonScreens={commonScreens}
                      unAuthScreens={unAuthScreens}
                      routeToPricing={this.routeToPricing}
                      appLogin={ROUTES.MOBILE_AUTH === activeRoute}
                      inComingBroker={inComingBroker}
                      marketingHomePageScrolled={marketingHomePageScrolled}
                    />
                  )}
                <Suspense fallback={this.fallback()}>
                  <div className={`${styles.screens} ${withBg ? styles.screensBg : ''}`}>
                    <div {...stylesProps}>
                      <div id="portalWindow">
                        <div id="highlightedWindow" />
                      </div>
                      <div className={`${styles.screensContent} ${isCondensed ? styles.leftContainerCondensed : hideSideBar
                        ? styles.container : styles.leftContainer}`}
                      >
                        {this.renderScreens({
                          authenticated, domainChange, activeRoute, toggleAuthModal, inComingBroker,
                        })}
                      </div>
                      {displayDevice >= DISPLAY_DEVICE.DESKTOP.value
                        && (
                          loggedIn || activeRoute === ROUTES.AUTH
                        ) && (
                          <SideBar
                            activeSideBarObj={activeSideBarObj}
                            containerStyles={`${isCondensed ? styles.rightContainerCondensed : styles.rightContainer} ${hideSideBar ? styles.rightContainerHidden : ''}`}
                            isDark={isDark}
                            hideSideBar={hideSideBar}
                            toggleSideBar={this.toggleSideBar}
                            isFetchingUser={isFetchingUser}
                            closeBtnStyles={styles.rightContainerCloseBtn}
                            sideBarRef={this.sideBarRef}
                            domainChange={domainChange}
                            updateGA={updateGA}
                            startTour={startTour}
                          />
                        )}
                    </div>
                    {activeSideBarObj && activeSideBarObj.showToggler
                      && !isMobile && displayDevice >= DISPLAY_DEVICE.DESKTOP.value && (
                        <div className={`${styles.sideBarToggler}  
                       ${hideSideBar ? '' : styles.sideBarTogglerHidden}`}
                        >
                          <button
                            type="button"
                            onClick={e => this.toggleSideBar(e, false)}
                            className={`${styles.sideBarTogglerBtn}`}
                          >
                            <Icon name={ICONS.SETTING} color={COLORS.WHITE} />
                          </button>
                        </div>
                      )}
                  </div>
                </Suspense>
                {SnackBar}
                <LoginModal
                  visible={showLoginModal}
                  isHomePage={isHomePage}
                  onDismiss={this.onDismissLoginModal}
                  domainChange={domainChange}
                  isLoggedIn={loggedIn}
                  history={history}
                  postLoginParams={postLoginParams}
                  sharedLogin={sharedLogin}
                  extraProps={{
                    inComingBroker,
                  }}
                />
                <CustomModal
                  visible={openWelcomeReward}
                  animationType="fade"
                  onDismiss={onDismissWelcomeReward}
                  dismissKey="openWelcomeReward"
                  containerStyles={styles.referralContainer}
                >
                  <WelcomeRewardModal
                    onDismiss={toggleModal}
                    dismissKey="openWelcomeReward"
                    updateGA={updateGA}
                  />
                </CustomModal>
                <CustomModal
                  visible={showDisclosureModal}
                  animationType="fade"
                  onDismiss={onDismissDisclosureModal}
                  dismissKey="showDisclosureModal"
                  disableBackDrop
                  containerStyles={styles.disclosureContainer}
                >
                  <DiscloureModal toggleDisclosureModal={onDismissDisclosureModal} />
                </CustomModal>
              </>
            )
          }}
        </Authenticator>
        <CustomModal
          visible={showDeeplinkModal}
          dismissKey="showDeeplinkModal"
          onDismiss={this.toggleDeepLinkModal}
          containerStyles={styles.deeplinkModal}
        >
          <DeeplinkModal
            continueInBrowser={this.toggleDeepLinkModal}
            openInApp={this.openInApp}
          />
        </CustomModal>
        {path && (
          <CustomModal
            visible={showLimitPopup}
            dismissKey="showLimitPopup"
            onDismiss={this.onDismissLimitPopup}
            containerStyles={styles.limitContainer}
          >
            {this.renderLimitPopup()}
          </CustomModal>
        )}
        <CustomModal
          visible={showUpgradeModal}
          dismissKey="showUpgradeModal"
          onDismiss={this.toggleUpgradeModal}
          containerStyles={styles.limitContainer}
        >
          {/* <UpgradeModal
            {...this.upgradeModalProps}
            routeToPayment={this.routeToPayment}
            onDismiss={this.toggleUpgradeModal}
          /> */}
          {this.renderLimitPopup()}
        </CustomModal>
        {authenticated && (
          <div>
            <ProductTour
              location={location}
              history={history}
              isMobile={isMobile}
            />
            {location.pathname !== pathname_mapping.Dashboard && <img src={ASSETS.TourHotspot} id="tour-dot" alt="hotspot_img" className={ptClassName} />}
          </div>
        )}
        {(ptSideBar && tourType !== PRODUCT_TOUR_SECTIONS.FINISHED) && (
          <button type="button" onClick={() => toggleTourSideBar(false)} style={{ pointer: 'default' }}>
            <div
              className={styles.backDrop}
              style={{ cursor: 'pointer', zIndex: 9999 }}
            />
          </button>
        )}
        {ptSideBar && <ProductTourSideBar history={history} isMobile={isMobile} />}
        {/* {location.pathname === pathname_mapping.Auth && !isZerodha && (
          <div id="jas">
            <Home location={location} />
            <Plans
              queryParams={this.queryParams}
              routeToPricing={this.routeToPricing}
              isLoggedIn={isLoggedIn}
              toggleAuthModal={toggleAuthModal}
            />
          </div>
        )} */}
        {/* {showLoading && <PageLoader fullScreen />} */}
      </main>
    )
  }
}

const stylesheet = {
  main: {
    height: '100vh',
    // do not change this other this are dependent on scrollBehavior
    // search for getElementById('main')
    overflow: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.screenBg,
    // pos = relative used for tour
    position: 'relative',
  },
  authScreens: {
  },
  referralContainer: {
    width: 325,
  },
  screensContent: {
    display: 'inline-block',
    transition: 'all 0.35s var(--anim-func-ease)',
  },
  screens: {
    minHeight: `calc(100% - ${SPACING.HEADER_HEIGHT_LARGE}px)`,
  },
  screensBg: {
    backgroundImage: `url(${ASSETS.LOGIN_BG})`,
    backgroundPosition: 'center',
  },
  leftContainer: {
    // flex: 0.77,
    width: `calc(100% - ${sideBarWidth + 40}px)`, // '72%',
    marginRight: 40,
  },
  leftContainerCondensed: {
    width: `calc(100% - ${condensedSideBarWidth + 40}px)`,
    marginRight: 40,
  },
  container: {
    width: '100%',
  },
  rightContainer: {
    // flex: 0.23,
    width: sideBarWidth,
    position: 'sticky',
    top: SPACING.HEADER_HEIGHT, // `${75 + (DIMENSIONS.HEIGHT * 0.06)}px`,
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: SPACING.SPACE_38,
    marginTop: SPACING.SPACE_30,
    opacity: 1,
    transition: 'all 350ms var(--anim-func-ease)',
    transform: 'translate(0px, 0px)',
  },
  rightContainerCondensed: {
    width: condensedSideBarWidth,
    position: 'sticky',
    top: SPACING.HEADER_HEIGHT, // `${75 + (DIMENSIONS.HEIGHT * 0.06)}px`,
    verticalAlign: 'top',
    display: 'inline-block',
    marginBottom: SPACING.SPACE_38,
    marginTop: SPACING.SPACE_30,
    zIndex: 9998,
    opacity: 1,
    transition: 'all 350ms var(--anim-func-ease)',
    transform: 'translate(0px, 0px)',
  },
  rightContainerHidden: {
    // transform: 'translate(294px, 0px)',
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
  },
  fallback: {
    zIndex: '99999',
    top: '0',
    height: '100%',
    position: 'fixed',
    width: '100%',
    background: '#f9f9f99e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBarToggler: {
    position: 'fixed',
    top: '100px',
    right: '36px',
    transition: 'transform 450ms var(--anim-func-ease)',
    opacity: 1,
    transform: 'translate(0px)',
    zIndex: 9998,
  },
  sideBarTogglerBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.bgSecondary,
    borderRadius: 6,
    border: `1px solid ${COLORS.BLUE}`,
    height: '40px',
    width: '52px',
    backgroundColor: COLORS.BLUE,
    // fill: theme.white,
    '&:hover': {
      '& > svg': {
      },
    },
  },
  sideBarTogglerHidden: {
    transform: 'translate(100px, 0)',
    opacity: 0,
  },
  rightContainerCloseBtn: {
    zIndex: 99,
    position: 'absolute',
    right: 30,
    top: 10,
  },
  upgradeModal: {
    maxWidth: 740,
    padding: 20,
  },
  upgradeModalUltimate: {
    padding: '20px 0',
    overflowX: 'hidden',
    maxWidth: 421,
  },
  deeplinkModal: {
    padding: 20,
  },
  toggleTour: {
    position: 'fixed',
    top: SPACING.SPACE_14,
    right: 65,
    zIndex: 9999,
    display: 'none',
    paddingBottom: 0,
  },
  backDrop: {
    backgroundColor: theme.backdropBg,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  disclosureContainer: {
    width: 650,
    maxWidth: '650px !important',
  },
  limitHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SPACING.SPACE_20,
  },
  limitContainer: {
    paddingBlock: SPACING.SPACE_30,
    padding: SPACING.SPACE_40,
  },
  // '@media only screen and (min-width: 1366px) and (max-width: 1440px)': {
  // },
  '@media only screen and (min-width: 1025px) and (max-width: 1366px)': {
    leftContainer: {
      marginRight: SPACING.SPACE_24,
      width: `calc(75% - ${SPACING.SPACE_24})`,
    },
    rightContainer: {
      width: '25%',
    },

  },
  '@media only screen and (min-width: 1366px)': {
    toggleTour: {
      display: 'block',
    },
  },
  '@media only screen and (min-width: 1901px)': {
    toggleTour: {
      display: 'none',
    },
  },
  '@media only screen and (min-width: 786px) and (max-width: 1024px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
  },
  '@media only screen and (max-width: 786px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
    screens: {
    },
    upgradeModalUltimate: {
      width: '100vw',
    },
  },
  '@media only screen and (max-width: 568px)': {
    upgradeModal: {
      height: '100%',
      maxHeight: 'unset',
      // overflow: 'hidden',
      margin: '0px auto',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    isLoggedIn: state.auth.isLoggedIn,
    isWasmLoaded: state.backtests.isWasmLoaded,
    loadWasm: state.backtests.loadWasm,
    loginError: state.auth.loginError,
    loginResp: state.auth.loginResp,
    defaultVersion: state.auth.defaultVersion,
    isFetchingUser: state.auth.isFetchingUser,
    showLoginModal: state.auth.showLoginModal,
    showFeatures: state.common.showFeatures,
    isLoginOnOrder: state.common.isLoginOnOrder,
    postLoginParams: state.auth.postLoginParams,
    sharedLogin: state.auth.sharedLogin,
    showDeeplinkModal: state.auth.showDeeplinkModal,
    // sessionValid: state.auth.sessionValid,
    // sessionValidError: state.auth.sessionValidError,
    // fetchVersionError: state.auth.fetchVersionError,
    // fetchVersionErrorMsg: state.auth.fetchVersionErrorMsg,

    websiteConfig: state.common.websiteConfig,
    greetingModal: state.common.greetingModal,
    startTour: state.common.startTour,
    ptSideBar: state.common.ptSideBar,
    finishedTour: state.common.finishedTour,
    activeTourIndex: state.common.activeTourIndex,
    tourType: state.common.tourType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getVersion: headers => dispatch(fetchVersion(headers)),
    sessionValidate: (params, headers) => dispatch(validateSession(params, headers)),
    login: (params, headers) => dispatch(loginUser(params, headers)),
    fetchVersionSuccess: data => dispatch(fetchVersionSuccess(data)),
    setPartnerRef: (params, headers) => dispatch(updatePartnerRef(params, headers)),
    updateToken: params => dispatch(setToken(params)),
    logOut: () => dispatch(logout()),
    getNotificationCount: () => dispatch(getNotificationCount()),
    updateSiteConfig: (field, config) => dispatch(updateWebSiteConfig(field, config)),
    toggleAuthModal: (state, params) => dispatch(toggleLoginModal(state, params)),
    toggleGreetingModal: (modalType, routeAfterActivation, showFeatures) => dispatch(
      toggleAuthGreeting(modalType, routeAfterActivation, showFeatures),
    ),
    toggleDeepLinkModal: (state, params) => dispatch(toggleDeeplinkModal(state, params)),
    updateWasmStatus: status => dispatch(changeWasmStatus(status)),
    showTour: (params, tourType) => dispatch(toggleTour(params, tourType)),
    setTourIndex: params => dispatch(updateTourIndex(params)),
    toggleTourSideBar: params => dispatch(togglePtSideBar(params)),
    setSideBarValue: params => dispatch(getSideBarValue(params)),
    pollUserDetails: (params, headers) => dispatch(pollUser(params, headers)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withTheme(stylesheet)(App),
  ),
)

// '@media only screen and (min-width: 1025px) and (max-width: 1366px)': {},
//   '@media only screen and (min-width: 786px) and (max-width: 1024px)': {},
//   '@media only screen and (max-width: 786px)': {},
