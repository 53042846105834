/* eslint-disable */
import React from 'react'
// import ReCAPTCHA from 'react-google-recaptcha'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet-async'
import CustomText from '../../UI/CustomText'
import Login from '../../components/Auth/Login'
import CustomModal from '../../UI/CustomModal'
import LinkBrokerLogin from '../../components/Auth/LinkBrokerLogin'
import ResetPassword from '../../components/Auth/ResetPassword'
import SignUpSuccess from '../../components/Auth/SignUpSuccess'
import ForgotPassword from '../../components/Auth/ForgotPassword'
import DotLoader from '../../UI/DotLoader'
import ReferralModal from '../../UI/ReferralModal'
import ABLogin from '../../components/Auth/ABLogin'
import PageLoader from '../../UI/PageLoader'

import { withTheme } from '../../Theme/ThemeProvider'
import { showSnackbar } from '../../UI/Snackbar'
import {
  BROKERS, emailRegex, loginBrokerMap, loginPageFeatures,
} from '../../utils/consts'
import {
  SPACING, theme, ASSETS, COLORS,
} from '../../Theme'
import {
  deleteCookies, getCookies, getHeaderUuid, headerGenerator, parseQuery, setCookie,
} from '../../utils/common'
import { signupPage, termsPage } from '../../utils/apiEndpoints'
import { appLoginRoutes, pathname_mapping } from '../../utils/navigationConstant'
import { authRoutes } from '../../AuthenticatedRoutes'
import SignUp from '../../components/Auth/SignUp'
import { isCtbFlow } from '../Create/Create-utils'
import Icons from '../../Theme/icons'
import Icon from '../../UI/Icon'

// const RECAPTCHA_SITE_KEY = '6Lfx5okaAAAAAMRN3S_0-HRsMfmrpkhvHSUTacUA'
const RECAPTCHA_TOKEN = 'Fv7qPPuL5LPUeXtG'

const {
  KiteLogo, ABLogo, UpstoxLogo, FivePaisaLogo, ICICILogo,
} = ASSETS

const ImageButton = ({
  styles,
  btnStyles,
  onPress,
  imgSrc,
  imgProps,
  textProps,
  text,
  params,
  loading,
  showText,
}) => {
  return (
    <button type="button" onClick={() => onPress(...params)} className={classnames(styles.imageBtn, !showText ? styles.imgBtn : '', btnStyles)}>
      {loading ? <img src={ASSETS.DotLoaderBlue} alt="loading" className={styles.loaderImg} />
        : <img src={imgSrc} alt={text} className={styles.btnImg} {...imgProps} />}
      {showText && !loading && (<CustomText weight="medium" className={styles.imgBtnText} size="small" {...textProps}>{text}</CustomText>)}
    </button>
  )
}
export const AUTH_ACTIVE_STATE_MAP = {
  LOGIN: 'login',
  AB_LOGIN: 'abLogin',
  PASSWORD_RESET: 'passwordReset',
  FORGOT_PASSWORD: 'forgotPassword',
  PASSWORD_RESET_SUCCESS: 'passwordResetSuccess',
  SIGN_UP_SUCCESS: 'signUpSuccess',
  SIGN_UP: 'signUp',
}

class Auth extends React.Component {
  constructor(props) {
    super(props)
    this.initialState = {
      emailValid: false,
      email: '',
      password: '',
      confirmPassword: '',
      confirmPasswordErr: false,
      passwordErr: false,
      passwordErrTxt: '',
      emailErr: false,
      abId: '',
      abIdErr: false,
      referralModal: false,
      showLimitPopup: true,
    }

    this.getHeaders()

    const { addBroker = null, activeState = AUTH_ACTIVE_STATE_MAP.LOGIN } = props
    this.addBroker = addBroker
    this.state = {
      isLogging: false,
      loginType: '',
      showConnectedMailPopup: false,
      linkBrokerLogin: false,
      emailVerified: false,
      btnPressed: false,
      ...this.initialState,
      errorModal: false,
      activeState,
      appLogin: false,
    }
    this.showPassword = false
    this.captchaRef = React.createRef()
    this.linkBrokerLoginProps = {}

    this.uuidHeader = {}
  }

  componentDidMount() {
    const {
      location, fetchVersion, inComingBroker,
    } = this.props
    const { search, pathname } = location
    const {
      auth, email, user_uuid, csrf, adding_broker, code, ref, ref_id,
    } = parseQuery(search)
    // let sharedPathname = ''
    // let pathParams = ''
    // if(pathname.includes('/login/zerodha/pathname')) {
    //   const [pathString, paramsString] = pathname.replace('https://streak.zerodha.com/login/zerodha/', '').split('&')
    //   sharedPathname = pathString.split('=')[1]
    //   pathParams = paramsString.split('=')[1]
    // }
    if (inComingBroker) {
      this.loginHandler(inComingBroker)
    } else {
      this.cookies = getCookies()
      if ((ref || this.cookies.ref_id) && pathname === pathname_mapping.Auth) {
        this.setState({ referralModal: true })
        this.ref_id = ref || this.cookies.ref_id
      }
      if (ref_id) {
        this.ref_id = ref_id
      }
      let shouldAutoLogin = true
      // directly login if broker is same
      const loginRoute = pathname.match(/^\/login\/[a-z1-9]*/g)
      if (loginRoute && pathname.split('/')[2]) {
        if (appLoginRoutes.includes(loginRoute[0])) {
          const savedBroker = this.cookies.broker
          const routeBroker = loginBrokerMap[pathname.split('/')[2]]
          if (savedBroker && routeBroker === savedBroker
            && this.cookies.token) {
            fetchVersion(headerGenerator(atob(this.cookies.token), atob(this.cookies.id)))
            shouldAutoLogin = false
          } else {
            deleteCookies(['token', 'id', 'broker'])
          }
        } else if (this.cookies.token) {
          shouldAutoLogin = false
        }
        const routePath = pathname.split(/^\/login\/[a-z1-9]*/g)[1]
        if (routePath && authRoutes.some(route => window.location.pathname.includes(route.path))) {
          setCookie({ pathname: routePath, pathParams: search })
        }
      }
      if (pathname === pathname_mapping.PasswordReset) {
        this.authToken = auth
        this.setState({ activeState: AUTH_ACTIVE_STATE_MAP.PASSWORD_RESET })
      } else if (pathname === pathname_mapping.ForgotPassword) {
        this.setState({ activeState: AUTH_ACTIVE_STATE_MAP.FORGOT_PASSWORD })
      } else if (pathname === pathname_mapping.SignUp) {
        this.setState({ activeState: AUTH_ACTIVE_STATE_MAP.SIGN_UP })
      }
      if (shouldAutoLogin) {
        if (pathname.includes(pathname_mapping.AppZerodhaLogin)) {
          // if(sharedPathname || pathParams) {
          //   setCookie({ pathname: atob(sharedPathname), pathParams: atob(pathParams) })
          // }
          this.loginHandler(BROKERS.ZERODHA.name)
          this.setState({ btnPressed: true, appLogin: true })
        } else if (pathname.includes(pathname_mapping.AppAngelLogin)) {
          this.loginHandler(BROKERS.ANGEL.name)
          if (email && adding_broker) {
            this.addBroker = {
              email, user_uuid, csrf, adding_broker,
            }
          }
          this.setState({ btnPressed: true, appLogin: true })
        } else if (pathname.includes(pathname_mapping.AppPaisaLogin)) {
          this.loginHandler(BROKERS.FIVE_PAISA.name)
          this.setState({ btnPressed: true, appLogin: true })
        } else if (pathname.includes(pathname_mapping.AppIciciLogin)) {
          this.loginHandler(BROKERS.ICICI.name)
          this.setState({ btnPressed: true, appLogin: true })
        } else if (pathname.includes(pathname_mapping.AppUpstoxLogin)) {
          if (!code) {
            this.loginHandler(BROKERS.UPSTOX.name)
            this.setState({ btnPressed: true, appLogin: true })
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userExist, loginResp, validatingEmail, signUp, isLogging, loginError, loginErrorMsg,
    } = this.props
    const {
      email, btnPressed, password, confirmPassword,
    } = this.state
    // called after email verifed (same as pressing continue button after email verify)
    if (isEmpty(loginResp) && userExist !== null && email
      && validatingEmail !== prevProps.validatingEmail && !validatingEmail) {
      this.setState({ emailVerified: true }, () => this.loginHandler(BROKERS.NONE.name))
    }
    if (userExist === false && signUp && prevProps.isLogging && !isLogging) {
      this.setState({ activeState: AUTH_ACTIVE_STATE_MAP.SIGN_UP_SUCCESS })
    }
    if (loginError && !prevProps.loginError && btnPressed) {
      let extraChange = {}
      if (loginErrorMsg && email && password && !confirmPassword) {
        extraChange = { passwordErr: true, passwordErrTxt: loginErrorMsg }
      }
      this.setState({ btnPressed: false, ...extraChange })
    }
  }

  getHeaders = () => {
    const appUUID = getHeaderUuid()
    this.uuidHeader = { 'X-APP-UUID': appUUID }
  }

  recaptchaCallback = (field) => {
    // if (token) {
    if (field === 'login') {
      const { email, password } = this.state
      const { emailLogin } = this.props
      const emailParams = {
        email: email.trim(),
        token: RECAPTCHA_TOKEN,
        password,
        broker: BROKERS.NONE.name, // token: RECAPTCHKEY,
      }
      emailLogin(emailParams)
    } else if (field === 'forgot_password') {
      const {
        forgot_password: {
          email,
        },
      } = this.state
      const params = { email: email.trim(), token: RECAPTCHA_TOKEN }
      this.props.forgotPassword(params, this.headers)
    }
    // }
  }

  recaptchaError = () => {
    this.setState({
      errorModal: true,
      errorMsg: 'Oops, something is wrong. Please refresh the page and try logging in again.',
      errorBtnFunc: () => window.location.reload(),
      errorBtnText: 'Refresh',
    })
  }

  asyncScriptOnLoad = (resp) => {
    const { loaded, errored } = resp
    const { errorModal } = this.state
    if (!loaded && errored) {
      this.recaptchaError()
      return
    }
    if (errorModal) {
      this.setState({ errorModal: false })
    }
    const val = this.captchaRef.current.getValue()
    if (val && this.captchaRef.current) {
      this.captchaRef.current.reset()
    }
    this.setState({ loading: false })
  }

  toggleConnectedMailPopup = () => {
    this.showPassword = false
    this.setState(prevState => ({
      showConnectedMailPopup: !prevState.showConnectedMailPopup,
      emailValid: false,
      email: '',
    }))
  }

  goToParticularBroker = () => {
    const { brokers_added: { brokers } } = this.props
    setTimeout(() => {
      this.loginHandler(brokers[0])
    }, 3500)
  }

  loginHandler = (brokerName, showBrokerAuth) => { // isConnectingBroker
    const {
      brokers_added, domainChange, userExist, // user_details, loginResp,
      loginShared, location,
    } = this.props
    const {
      email: emailValue, password, emailValid, passwordErr, confirmPassword,
    } = this.state
    const { pathname, pathParams } = getCookies()
    //  const { email, user_uuid } = user_details

    //  const linkingParams =
    // `?UID=${user_uuid}&email=${email}&csrf=${loginResp.csrf}&adding_broker=true`
    // debugger
    switch (brokerName) {
      case BROKERS.ZERODHA.name: {
        setCookie({ broker: BROKERS.ZERODHA.name })
        if (loginShared) {
          window.open(`https://streak.zerodha.com/login/zerodha?pathname=${btoa(pathname)}&pathParams=${btoa(pathParams)}`, '_self')
          return
        }
        //  const webviewUrl = isConnectingBroker ? kite_loginUrl + linkingParams : loginUrl
        domainChange({ loginWithBroker: BROKERS.ZERODHA.name, showBrokerAuth })
        break
      }

      case BROKERS.ANGEL.name: {
        setCookie({ broker: BROKERS.ANGEL.name })
        domainChange({ loginWithBroker: BROKERS.ANGEL.name, showBrokerAuth })
        break
        // setCookie({ broker: BROKERS.ANGEL.name })
        // this.setState({ ...this.initialState, activeState: AUTH_ACTIVE_STATE_MAP.AB_LOGIN })
        // break
      }
      case BROKERS.UPSTOX.name: {
        setCookie({ broker: BROKERS.UPSTOX.name })
        this.setState({ loginType: BROKERS.UPSTOX.name, btnPressed: true })
        domainChange({ loginWithBroker: BROKERS.UPSTOX.name, showBrokerAuth })
        break
      }
      case BROKERS.FIVE_PAISA.name: {
        setCookie({ broker: BROKERS.FIVE_PAISA.name })
        domainChange({ loginWithBroker: BROKERS.FIVE_PAISA.name, showBrokerAuth })
        break
      }
      case BROKERS.ICICI.name: {
        setCookie({ broker: BROKERS.ICICI.name })
        domainChange({ loginWithBroker: BROKERS.ICICI.name, showBrokerAuth })
        break
      }
      case BROKERS.NONE.name: {
        if (brokers_added && brokers_added.brokers && brokers_added.brokers.length) {
          this.showPassword = false
          if (this.captchaRef.current) {
            this.captchaRef.current.reset()
          }
          if (brokers_added.brokers.length > 1) {
            this.linkBrokerLoginProps = {
              fromAuth: true,
              user_name: emailValue,
            }
            this.toggleModal('linkBrokerLogin')
            // navigation.navigate(NAVIGATIONS.LINK_BROKER_LOGIN.name,
            //   {
            //     fromAuth: true,
            //     user_name: emailValue,
            //     emailConnectedBrokers: brokers_added.brokers,
            //   })
          } else if (brokers_added.brokers.length === 1) {
            this.toggleConnectedMailPopup()
            this.goToParticularBroker()
            this.connectedBroker = brokers_added.brokers[0]
          }
          return
        }
        if (emailValue?.length === 0 || !emailValid) {
          showSnackbar('Please enter a valid email')
          this.setState({ emailErr: true, btnPressed: false })
          return
        }
        if (emailValid && !this.showPassword) {
          this.showPassword = true
          this.setState({ passwordErr: false, password: '', btnPressed: false })
          return
        }
        if (password.length < 8) {
          if (password.length === 0) {
            this.setState({ passwordErrTxt: 'Password field cannot be empty', passwordErr: true, btnPressed: false })
            // showSnackbar('Password field cannot be empty', {}, 0)
          } else if (password.length < 8) {
            this.setState({ passwordErrTxt: 'Password should be more than 8 characters', passwordErr: true, btnPressed: false })
            // showSnackbar('Password should be more than 8 characters', {}, 0)
          }
          // this.captchaRef.current.reset()
          // this.setState({ passwordErr: true,  })
          return
        }
        if (!userExist && !passwordErr && (!confirmPassword || confirmPassword !== password)) {
          this.setState({
            passwordConfirmErr: true, passwordErrTxt: 'Password fields are not matching', passwordErr: true, btnPressed: false,
          })
          // showSnackbar('Password fields are not matching', {}, 0)
          return
        }
        this.setState({
          loginType: BROKERS.NONE.name,
          passwordErr: false,
          passwordConfirmErr: false,
        })
        this.recaptchaCallback('login')
        break
      }
      default: {
        break
      }
    }
  }

  loginWithEmail = () => {
    const { emailVerified, email } = this.state
    const { validateEmail, validatingEmail, toggleLoginModal } = this.props
    const emailValid = email && email.trim() && emailRegex.test(email)
    if (emailValid && !validatingEmail && !emailVerified) {
      this.setState({ emailValid: true })
      validateEmail({ email })
      return
    }
    if (!emailValid) {
      this.setState({ emailValid: false, emailErr: true })
      return
    }
    this.setState({
      btnPressed: true,
    }, () => {
      toggleLoginModal(false)
      this.loginHandler(BROKERS.NONE.name)
    })
  }

  onChangeText = (e, inputKey) => {
    const { value } = e.target
    let extraChanges = {}
    if (inputKey === 'email') {
      this.showPassword = false
      extraChanges = { emailVerified: false }
    }
    this.setState({ [inputKey]: value, [`${inputKey}Err`]: false, ...extraChanges })
  }

  renderConnectedPopUp = () => {
    const { styles, connectedBrokers } = this.props
    const broker = (connectedBrokers[0] && connectedBrokers[0].label) || ''
    return (
      <div className={styles.emailConnectedModalContainer}>
        <div className={styles.emailConnectedModalContent}>
          <CustomText size="medium_1" weight="semi_bold">Email already connected</CustomText>
          <CustomText
            center
            size="small"
            style={{ margin: SPACING.SPACE_10 }}
          >
            {`You will be redirected to ${broker} login since your email has been connected to your ${broker} account.`}
          </CustomText>
          <DotLoader props={{ className: styles.dotLoader }} />
        </div>
      </div>
    )
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onKeyDown = (ev, field) => {
    const { key } = ev
    if (key === 'Enter') {
      switch (field) {
        case 'email': {
          this.loginWithEmail()
          break
        }

        case 'password': {
          // const { userExist, signUp } = this.props
          this.loginWithEmail()
          break
        }

        case 'confirmPassword': {
          this.loginWithEmail()
          break
        }

        default:
          break
      }
    }
  }

  onForgotPassword = () => {
    this.setState({
      activeState: AUTH_ACTIVE_STATE_MAP.FORGOT_PASSWORD,
    })
  }

  loginWithAB = () => {
    const {
      loginUser, isLogging,
      toggleLoginModal,
    } = this.props
    if (isLogging) return

    const { abId, password } = this.state
    if (abId?.length === 0) {
      showSnackbar('Please enter user id')
      this.setState({ abIdErr: true, btnPressed: false })
      return
    }
    if (password?.length === 0) {
      showSnackbar('Password cannot be empty')
      this.setState({ passwordErr: true, btnPressed: false })
      return
    }
    let refParams = {}
    if (this.cookies.ref_id && !this.ref_id) {
      this.ref_id = this.cookies.ref_id
    }
    if (this.ref_id) {
      refParams = { ref_id: this.ref_id, device: 'desktop' }
    }
    let params = {
      username: abId,
      password,
      broker: BROKERS.ANGEL.name,
      service: BROKERS.ANGEL.serviceName,
      ...refParams,
    }
    if (this.addBroker) {
      params = {
        ...params,
        ...this.addBroker,
      }
    }
    toggleLoginModal(false)
    loginUser(params, this.uuidHeader)
  }

  onABLogin = (ev, field) => {
    const { key } = ev
    if (key === 'Enter' && field === 'password') {
      this.loginWithAB()
    }
  }

  toggleAbLogin = (addBroker) => {
    this.addBroker = addBroker
    this.setState({ ...this.initialState, activeState: AUTH_ACTIVE_STATE_MAP.AB_LOGIN })
  }

  routeTo = (pathname, activeState) => {
    const { history } = this.props
    if (pathname) {
      history.replace(pathname)
    }
    if (activeState) {
      this.setState({ ...this.initialState, activeState: AUTH_ACTIVE_STATE_MAP[activeState] })
    }
  }

  renderTnc = () => {
    const { styles } = this.props
    return (
      <div className={styles.tncContainer}>
        <CustomText size="small" style={{ whiteSpace: 'pre-line' }}>
          {'By Continuing you agree to our \n'}
          <a href={termsPage} rel="noreferrer" target="_blank" className={styles.tncLink}>
            Terms & Conditions
          </a>
        </CustomText>
      </div>
    )
  }

  renderSignupBtn = () => {
    const { styles, updateGA } = this.props
    const signUpGA = () => updateGA('DematAccount', '', '', 'Home')
    return (
      <div className={styles.tncContainer}>
        <CustomText size="small" style={{ whiteSpace: 'pre-line' }}>
          {"Don't have any broker account? \n"}
          <Link to={signupPage} rel="noreferrer" onClick={signUpGA} className={styles.tncLink}>
            Sign Up
          </Link>
        </CustomText>
      </div>
    )
  }

  renderOtherBrokerLogin = () => {
    const {
      styles, isLogging, signUp, isHomePage, loginBtnStyles,
      loginModal,
    } = this.props
    const { location } = window
    const { hostname, pathname } = location
    const isZerodha = hostname === 'streak.zerodha.com'
    const { loginType, activeState } = this.state
    const showText = (((!signUp && !this.showPassword))
      && activeState === AUTH_ACTIVE_STATE_MAP.LOGIN)
    return (
      <>
        {/* {!isHomePage && (
        <div className={styles.loginBroker}>
          <div className={styles.seperator} />
          <CustomText color="textSecondary"
          className={styles.loginBrokerText}>or Log in with broker</CustomText>
        </div>
        )} */}
        <div style={{ textAlign: 'center' }}>
          {((!loginModal && pathname === '/login') || (loginModal && pathname !== '/login')) && (
            <ImageButton
              onPress={this.loginHandler}
              imgSrc={KiteLogo}
              params={[BROKERS.ZERODHA.name, false]}
              btnStyles={classnames(styles.loginBtn, styles.kiteLoginBtn, loginBtnStyles)}
              text="Log in with Kite"
              styles={styles}
              loading={loginType === BROKERS.ZERODHA.name && isLogging}
              showText={isHomePage || showText}
            />
          )}
          {!isZerodha
            && (
            <>
              <ImageButton
                onPress={this.loginHandler}
                imgSrc={ICICILogo}
                params={[BROKERS.ICICI.name, false]}
                btnStyles={classnames(styles.loginBtn, styles.paisaLoginBtn, loginBtnStyles)}
                text="Log in with ICICIdirect"
                styles={styles}
                loading={loginType === BROKERS.ICICI.name && isLogging}
                showText={isHomePage || showText}
              />
              <ImageButton
                onPress={this.loginHandler}
                imgSrc={UpstoxLogo}
                params={[BROKERS.UPSTOX.name, false]}
                btnStyles={classnames(styles.loginBtn, styles.upLoginBtn, loginBtnStyles)}
                text="Log in with Upstox"
                styles={styles}
                loading={loginType === BROKERS.UPSTOX.name && isLogging}
                showText={isHomePage || showText}
              />
              <ImageButton
                onPress={this.loginHandler}
                imgSrc={ABLogo}
                params={[BROKERS.ANGEL.name]}
                btnStyles={classnames(styles.loginBtn, styles.abLoginBtn, loginBtnStyles)}
                text="Log in with Angel One"
                styles={styles}
                loading={loginType === BROKERS.ANGEL.name && isLogging}
                showText={isHomePage || showText}
              />
              <ImageButton
                onPress={this.loginHandler}
                imgSrc={FivePaisaLogo}
                params={[BROKERS.FIVE_PAISA.name, false]}
                btnStyles={classnames(styles.loginBtn, styles.paisaLoginBtn, loginBtnStyles)}
                text="Log in with 5 Paisa"
                styles={styles}
                loading={loginType === BROKERS.FIVE_PAISA.name && isLogging}
                showText={isHomePage || showText}
              />
            </>
            )}
          {/* {this.renderSignupBtn()} */}
        </div>
      </>
    )
  }

  onBackPress = (pathname) => {
    const { history } = this.props
    if (pathname) {
      history.replace(pathname)
    }
    this.setState({ activeState: AUTH_ACTIVE_STATE_MAP.LOGIN })
  }

  renderRightContainer = () => {
    const {
      userExist, isFetchingVersion, isFetchingUser, validatingEmail, styles,
      rightContentStyles, loginContainerStyles, loginBrokerContainerStyles,
      fieldInputStyles, resetContainerStyles, forgotContainerStyles,
      isHomePage, sharedLogin, location,
    } = this.props
    const {
      activeState, showConnectedMailPopup,
      // isLogging, loginType, btnPressed, email,
      // password, passwordErr, emailErr, confirmPassword, confirmPasswordErr, passwordErrTxt,
    } = this.state
    if (showConnectedMailPopup) {
      return this.renderConnectedPopUp()
    }

    if (activeState === AUTH_ACTIVE_STATE_MAP.LOGIN) {
      // const btnDisabled = isFetchingVersion || isFetchingUser || isLogging || validatingEmail
      return (
        <div
          className={classnames(styles.rightContent,
            sharedLogin ? styles.rightContentLogin : null,
            rightContentStyles)}
        >
          {/* <Login
            loginHandler={this.loginHandler}
            onForgotPassword={this.onForgotPassword}
            disabled={btnPressed || btnDisabled}
            loginType={loginType}
            isLogging={isLogging}
            loginWithEmail={this.loginWithEmail}
            showPassword={this.showPassword}
            email={email}
            password={password}
            passwordErr={passwordErr}
            emailErr={emailErr}
            onChangeText={this.onChangeText}
            styles={styles}
            onKeyDown={this.onKeyDown}
            passwordConfirm={confirmPassword}
            confirmPasswordErr={confirmPasswordErr}
            showForgotPassword={userExist === true}
            showConfirmPassword={!userExist && this.showPassword}
            passwordErrTxt={passwordErrTxt}
            loginContainerStyles={loginContainerStyles}
            fieldInputStyles={fieldInputStyles}
            isHomePage={isHomePage}
            sharedLogin={sharedLogin}
            location={location}
          /> */}
          <div className={classnames(styles.loginBrokerContainer, loginBrokerContainerStyles)}>
            {this.renderOtherBrokerLogin()}
            {this.renderTnc()}
          </div>
        </div>
      )
    }
    if (activeState === AUTH_ACTIVE_STATE_MAP.PASSWORD_RESET) {
      return (
        <div className={classnames(styles.rightContent, rightContentStyles)}>
          <ResetPassword
            containerStyles={resetContainerStyles}
            authToken={this.authToken}
            routeTo={this.routeTo}
          />
          {this.renderOtherBrokerLogin()}
          {this.renderTnc()}
        </div>
      )
    }
    if (activeState === AUTH_ACTIVE_STATE_MAP.SIGN_UP) {
      return (
        <div className={classnames(styles.rightContent, rightContentStyles)}>
          <SignUp
            containerStyles={resetContainerStyles}
            routeTo={this.routeTo}
            showBackBtn
            onBackPress={this.onBackPress}
          />
          {this.renderTnc()}
        </div>
      )
    }
    if (activeState === AUTH_ACTIVE_STATE_MAP.SIGN_UP_SUCCESS) {
      return (
        <SignUpSuccess />
      )
    }
    if (activeState === AUTH_ACTIVE_STATE_MAP.FORGOT_PASSWORD) {
      return (

        <div className={classnames(styles.rightContent, rightContentStyles)}>
          <ForgotPassword
            containerStyles={forgotContainerStyles}
            showBackBtn
            onBackPress={this.onBackPress}
          />
          <div className={classnames(styles.loginBrokerContainer, loginBrokerContainerStyles)}>
            {this.renderOtherBrokerLogin()}
            {this.renderTnc()}
          </div>
        </div>
      )
    }
    return null
  }

  updateReferralAnalytics = (a, l, v) => {
    const { updateGA } = this.props
    updateGA(a, l, v, 'Pop-ups')
  }

  onDismissReferral = (modalKey) => {
    this.updateReferralAnalytics('Sign up', 'Close')
    this.setState({ [modalKey]: false })
  }

  renderLimitPopup = () => {
    const { styles } = this.props

    return (
      <>
        <div className={styles.limitHeader}>
          <div />
          <CustomText size="medium" weight="medium" color={COLORS.RED}>Important Notice!</CustomText>
          <button
            type="button"
            onClick={() => this.toggleModal('showLimitPopup')}
            style={{
              position: 'relative',
              top: '-20px',
              left: '20px',
            }}
          >
            <Icon
              name={Icons.CLOSE}
              size={12}
            />
          </button>
        </div>
        <CustomText style={{ marginBlock: SPACING.SPACE_20 }} center>
          A new user without a valid paid subscription plan
          will not be able to use the platform (pro.streak.tech).
        </CustomText>
        <CustomText center>
          All the existing clients with valid subscriptions, can use this platform (pro.streak.tech)
          till your plan is valid. You will not be able to subscribe to any new plans.
        </CustomText>
      </>
    )
  }

  renderLimitBanner = () => {
    const { styles } = this.props
    return (
      <div className={styles.limitBanner}>
        <CustomText size="medium" weight="medium">Important Notice!</CustomText>
        <CustomText style={{ marginBlock: SPACING.SPACE_5 }}>
          A new user without a valid paid subscription plan
          will not be able to use the platform (pro.streak.tech).
          All the existing clients with valid subscriptions, can use this platform till your plan
          is valid. You will not be able to subscribe to any new plans.
        </CustomText>
      </div>
    )
  }

  render() {
    const {
      styles, isLogging, isMobile, isHomePage, rightContainerStyles, sharedLogin,
      loginModal, authModal = false, inComingBroker, location,
    } = this.props
    const {
      linkBrokerLogin, activeState, password, passwordErr,
      abId, appLogin, referralModal, showLimitPopup,
    } = this.state
    const { pathname } = location
    const authPage = pathname === pathname_mapping.Auth
    const path = location.pathname.includes(pathname_mapping.Auth)

    const seoTitle = "Streak Zerodha | India's Best Systematic Trading Platform"
    const description = 'Login to the paid Streak Zerodha platform to deploy strategies live in the markets'
    const keywords = 'Live Deployement, Order placement, AngelOne Streak login'
    const leftContainerText = pathname === pathname_mapping.SignUp ? 'Sign up to' : 'Sign in to'
    if ((appLogin && activeState !== AUTH_ACTIVE_STATE_MAP.AB_LOGIN) || inComingBroker) {
      return <PageLoader fullScreen />
    }
    if (activeState === AUTH_ACTIVE_STATE_MAP.AB_LOGIN) {
      return (
        <ABLogin
          styles={styles}
          loginWithAB={this.loginWithAB}
          disabled={isLogging}
          passwordErr={passwordErr}
          onChangeText={this.onChangeText}
          password={password}
          abId={abId}
          onKeyDown={this.onABLogin}
          authModal={authModal}
          isMobile={isMobile}
          sharedLogins
        />
      )
    }
    return (
      <div className={styles.container}>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={description} />
          { /* End standard metadata tags */}
          { /* Facebook tags */}
          <meta property="og:title" content={seoTitle} />
          <meta property="og:description" content={description} />
          { /* End Facebook tags */}
          { /* Twitter tags */}
          <meta name="twitter:title" content={seoTitle} />
          <meta name="twitter:description" content={description} />
          { /* End Twitter tags */}
          { /* Google tags */}
          <meta name="keywords" content={keywords} />
          { /* End Google tags */}
        </Helmet>
        <CustomModal
          visible={linkBrokerLogin}
          onDismiss={this.toggleModal}
          dismissKey="linkBrokerLogin"
          position="right"
          containerStyles={styles.linkBrokerModal}
        >
          <LinkBrokerLogin
            onDismiss={this.toggleModal}
            dismissKey="linkBrokerLogin"
            toggleAbLogin={this.toggleAbLogin}
            {...this.linkBrokerLoginProps}
          />
        </CustomModal>
        <CustomModal
          visible={referralModal}
          animationType="fade"
          onDismiss={this.onDismissReferral}
          dismissKey="referralModal"
          containerStyles={styles.referralContainer}
        >
          <ReferralModal
            onDismiss={this.toggleModal}
            dismissKey="referralModal"
            updateAnalytics={this.updateReferralAnalytics}
          />
        </CustomModal>
        <CustomModal
          visible={showLimitPopup && path}
          onDismiss={this.toggleModal}
          dismissKey="showLimitPopup"
          containerStyles={styles.limitContainer}
        >
          {this.renderLimitPopup()}
        </CustomModal>
        {path && this.renderLimitBanner()}
        {/* <CustomModal
          visible={showConnectedMailPopup}
          onDismiss={this.toggleModal}
          dismissKey="showConnectedMailPopup"
          containerStyles={styles.connectedMailModal}
        >

        </CustomModal> */}
        <div className={styles.content} style={{ marginTop: path ? 120 : 0 }}>
          {!isMobile && !isHomePage && !loginModal && !sharedLogin && (
            <div className={styles.leftContainer}>
              <div className={styles.leftContent}>
                <CustomText weight="medium" size="large_25">{leftContainerText}</CustomText>
                <CustomText weight="semi_bold" size="large_55" className={styles.streakText}>Streak Pro</CustomText>
                <div>
                  {authPage && loginPageFeatures.map((item) => {
                    const { icon, title } = item
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name={icon} size={50} />
                        <CustomText size="medium" weight="bold" style={{ marginBlock: SPACING.SPACE_16 }}>{title}</CustomText>
                      </div>
                    )
                  })}
                </div>
                {/* <CustomText size="regular_1">If you don't have an account</CustomText> */}
                {/* <CustomText size="regular_1">
              {'You can\n'}
              <a
                href={pathname_mapping.SignUp}
                className={styles.registerLink}
              >
                Register here!
              </a>
            </CustomText> */}
              </div>
            </div>
          )}

          <div className={classnames(loginModal
            ? styles.rightContainerModal : styles.rightContainer, rightContainerStyles)}
          >
            {this.renderRightContainer()}
            {/* {this.showPassword ? (
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={value => this.recaptchaCallback('login', value)}
            // onExpired={() => this.expirationCallback(recaptchaType)}
              onErrored={this.recaptchaError}
              ref={this.captchaRef}
              asyncScriptOnLoad={this.asyncScriptOnLoad}
              size="invisible"
            />
          ) : null} */}
          </div>
        </div>
      </div>
    )
  }
}

const stylesheet = ({
  container: {
    // alignItems: 'center',
    marginTop: '4%',
    padding: `0 0 ${SPACING.SPACE_38} 0`,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  referralContainer: {
    backgroundColor: `${theme.peach} !important`,
    width: 325,
  },
  limitContainer: {
    paddingBlock: SPACING.SPACE_30,
    padding: SPACING.SPACE_40,
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: 484,
    // backgroundImage: `url(${ASSETS.LOGIN_BG})`,
  },
  leftContent: {
  },
  registerLink: {},
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5% !important',
    paddingTop: '2%',
    flex: 1,
  },
  rightContainerLogin: {
    padding: 'unset',
    display: 'unset',
    paddingRight: '0% !important',
  },
  rightContentLogin: {
    maxWidth: '519px !important',
  },
  rightContainerModal: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%',
    flex: 1,
  },
  emailConnectedModalContainer: {
    textAlign: 'center',
    width: '100%',
    maxWidth: '380px',
    padding: '40px 30px',
    borderRadius: '16px',
    // marginTop: '12%',
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    maxHeight: 250,
  },
  emailConnectedModalContent: {
    width: '85%',
    margin: 'auto',
  },
  dotLoader: {
    width: 60,
    marginTop: SPACING.SPACE_20,
  },
  connectedMailModal: {
    maxWidth: 400,
  },
  linkBrokerModal: {
    height: '100%',
  },
  rightContent: {
    maxWidth: 360,
    padding: '24px 42px',
    borderRadius: '16px',
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    width: '100%',
    // minHeight: 'calc(100vh - 150px)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3%',
  },
  loginBrokerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  // login styles
  loginContainer: {
    width: '100%',
  },
  title: {

  },
  fieldInput: {
    backgroundColor: theme.inputLightBg,
  },
  passwordInputContainer: {
    marginTop: SPACING.SPACE_20,
  },
  emailInputContainer: {
    marginTop: SPACING.SPACE_20,
  },
  inputWrapper: {},
  recover: {
    marginTop: SPACING.SPACE_10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  recoverBtn: {
  },
  continueBtn: {
    margin: `${SPACING.SPACE_20} 0`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  loginBroker: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${SPACING.SPACE_10} 0 ${SPACING.SPACE_28}`,
  },
  loginBrokerText: {
    backgroundColor: theme.bgPrimary,
    zIndex: '9',
    padding: `0 ${SPACING.SPACE_6}`,
  },
  seperator: {
    height: '1px',
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.borderColor,
  },
  loginBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    marginTop: '20px',
    border: `1px solid ${theme.borderColor}`,
    borderRadius: '6px',
    width: '100%',
    // transition: 'display 0.3s var(--anim-func-ease)',
    backgroundColor: theme.bgPrimary,
    '&:hover': {
      backgroundColor: theme.hover,
    },
  },
  brokerLoginSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  brokerLoginSectionCol: {
    flexDirection: 'column',
  },
  btnImg: {
    width: '16px',
  },
  imgBtnText: {
    marginLeft: SPACING.SPACE_10,
  },
  imgBtn: {
    borderRadius: '50%',
    width: 46,
    margin: `0 ${SPACING.SPACE_20} ${SPACING.SPACE_10}`,
    height: 46,
    verticalAlign: 'top',
    display: 'inline-flex',
    '& > img': {
      width: 22,
    },
  },
  kiteLoginBtn: {
  },
  abLoginBtn: {},
  paisaLoginBtn: {},
  tncContainer: {
    textAlign: 'center',
    margin: `${SPACING.SPACE_28} 0 ${SPACING.SPACE_10}`,
    flex: '1',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
  },
  tncLink: {},

  // ab login
  abLoginContainer: {
    textAlign: 'center',
    maxWidth: 440,
    padding: '32px 42px',
    borderRadius: '16px',
    backgroundColor: theme.bgPrimary,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    width: '100%',
    margin: 'auto',
    minHeight: 'calc(100vh - 120px)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: SPACING.SPACE_28,
  },
  abLoginModalContainer: {
    minHeight: '100%',
  },
  abLogo: {
    margin: 'auto',
    width: '100%',
    maxWidth: '240px',
    display: 'block',
    paddingRight: '16px',
  },
  abLoginPageBtn: {
    margin: `${SPACING.SPACE_28} 0 ${SPACING.SPACE_20}`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  noAccount: {
    textAlign: 'left',
    marginBottom: SPACING.SPACE_20,
  },
  abTncContainer: {
    flex: '1',
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'center',
  },
  loginInstructionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: SPACING.SPACE_20,
  },
  abLoginInstructionImg: {
    width: '100%',
  },
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: SPACING.SPACE_20,
  },
  stepRow: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  stepCountText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: 8,
    backgroundColor: theme.orangeLightBg,
    padding: SPACING.SPACE_4,
  },
  streakText: {
    position: 'relative',
    left: '-5px',
  },
  loaderImg: {
    maxWidth: 40,
  },
  signupBtn: {
    margin: `${SPACING.SPACE_20} 0`,
    width: '100%',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
  },
  limitHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: SPACING.SPACE_20,
  },
  limitBanner: {
    position: 'fixed',
    top: 80,
    width: '85%',
    backgroundColor: COLORS.ORANGE_100,
    padding: SPACING.SPACE_10,
    borderRadius: 10,
    zIndex: 99,
  },
  '@media only screen and (max-width: 786px)': {
    rightContainer: {
      justifyContent: 'center',
      paddingRight: '0',
      marginTop: SPACING.SPACE_48,
    },
  },
})

export default withTheme(stylesheet)(Auth)
